import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='ინგლისური ენის კურსები' />
    <Divider />
    <Stack>
      <Main>
        {/* <PageTitle
          header="სწავლა და დასაქმება"
          subheader='ინგლისურის ენის კურსები ონლაინ'
        /> */}
        <ContactInfo />
        <Divider />
<Section>
          <Card variant='paper'>
          <Text variant='p'>
          ჩვენ გვჯერა რომ ინგლისური ენის ცოდნა საშუალებას მოგცემთ:
            </Text>
            <Text variant='p'>
            - დაეუფლოთ ციფრული საქმიანობისთვის საჭირო უნარებს
            </Text>
            <Text variant='p'>
            - შეძლოთ სამსახურის მოძიება გლობალურად
            </Text>
            <Text variant='p'>
            - იმუშაოთ დისტანციურად
            </Text>
            <Text variant='p'>
            - გამოიმუშაოთ მაღალი ანაზღაურება
            </Text>
      
          </Card>
        </Section>
        <Divider />
        {/* <ContactForm /> */}
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />

      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
